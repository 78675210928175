<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<el-alert title="操作提示" type="success" class="margin-bottom">
			<div>
				1. 标识“*”的选项为必填项，其余为选填项。<br />
				2. 请按提示栏信息进行商品添加。
			</div>
		</el-alert>
		<div class="box">
			<div class="form_box">
				<!-- {{form.permissions}} -->
				<el-form ref="form" :model="form" label-width="80px" v-loading="loading">
					<el-form-item label="套餐名称" prop="name" :rules="rules.required">
						<el-input v-model="form.name" maxlength="30"></el-input>
						<!-- <span class="text-grey margin-left">套餐名称最多可输入20个字符！</span> -->
					</el-form-item>
					<el-form-item label="卡片背景" prop="cover" :rules="rules.required">
						<el-radio v-model="form.cover" :label="bg" v-for="(bg,index) in cardBgOptions" :key="index">
							<el-image style="width:100px;height: 50px;" :src="bg" fit="cover"></el-image>
						</el-radio>
					</el-form-item>
					<!-- <el-form-item label="所属分类" prop="classify" :rules="rules.required">
						<el-select v-model="form.classify" placeholder="请选择">
							<el-option v-for="item in classify" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item label="套餐描述">
						<el-input v-model="form.desc"></el-input>
					</el-form-item>
					<el-form-item label="有效期" prop="validity" :rules="rules.required">
						<el-select v-model="form.validity" placeholder="请选择">
							<el-option label="一年" :value="12"></el-option>
							<el-option label="半年" :value="6"></el-option>
							<el-option label="一个月" :value="1"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="上架">
						<el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit()">保存</el-button>
						<!-- <el-button type="primary" @click="onSubmit()">保存并继续添加</el-button> -->
						<el-button @click="$router.back()">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import rules from '../../common/common.rules.js'
	export default {
		data() {
			return {
				rules,
				classify: [],
				loading: false,
				cardBgOptions: [
					'http://qiniu.memexia.com/temp/IMLUa1qqi6OHuikqMdT8DVfFeD508vrcV84rW8qC.jpeg',
					'http://qiniu.memexia.com/temp/9iBYLd2DjCIbclfT2pG9JCJvP0J4uoYGrbCcPiUb.jpeg',
					'https://qiniu.memexia.com/uploads/20210104/47e31f19d4342017731040ec45c59cad.png',
					'https://qiniu.memexia.com/uploads/20210104/c1ae71be2625dda90489049d315b4024.png',
					'https://qiniu.memexia.com/uploads/20210104/72ab30eff9a8c7634c458c6df747441e.png'
				], // 背景图片选项
				form: {
					id: this.$route.params.id, // int 服务ID
					name: '', // string 服务名称
					// classify: undefined, // int 分类ID
					cover: undefined, // string 封面缩略图
					desc: undefined, // string 服务描述
					validity: '',// 有效期
					status: 1, // 上架
				}
			};
		},
		computed: {
			title() {
				if (this.form.id) {
					return '编辑套餐'
				} else {
					return '新增套餐'
				}
			}
		},
		mounted() {
			// 获取套餐分类
			this.$api.group.getClassify().then(res => {
				this.classify = res.data
			})
			if (this.form.id) {
				this.$api.group.getGroup({
					id: this.form.id
				}).then(res => {
					this.form = res.data
				})
			}
		},
		methods: {
			onSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.group.save(this.form).then(res => {
							loading.close();
							if (res.code == 200) {
								// 保存成功
								this.$message.success(`保存成功`);
								this.$router.back();
							} else {
								this.$message.error(res.msg);
							}
						}).catch(err => {
							loading.close();
							this.$alert(err);
						});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		},
	};
</script>

<style scoped lang="scss">
	.form_box {

		.el-input,
		.el-textarea {
			width: 500px;
		}
	}

</style>
